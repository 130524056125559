// 移动端开通会员
<template>
  <div class="m_total" id="mtotal">
    <!-- 置顶top -->
    <Mtop></Mtop>
    <div v-if="$store.state.islogin">
      <!-- <div class="mplay_title1_block"></div> -->
      <template v-if="$store.state.mhome">
        <div class="mplay_title1">
          <img src="../../assets/img/left2.png" @click="YS.routeBack()" alt="返回首页" />
          <a>会员充值</a>
        </div>
        <div class="mplay_title1_block"></div>
        <div
          class="vipbody"
          :class="
            user && Date.parse(new Date()).toString().substr(0, 10) < user.vip_expired_time
              ? 'vipbody_1'
              : 'vipbody_2'
          "
        >
          <div class="vip_user" @click="unloginCli">
            <img
              :src="$store.state.user.avatar ? $store.state.user.avatar : ''"
              :onerror="$store.state.defaulava"
              alt="用户头像"
            />
            <div class="mvip_rig">
              <a>
                {{
                  !user
                    ? '还未登录，点击立即登录'
                    : $store.state.user.nickname
                    ? $store.state.user.nickname
                    : $store.state.user.username
                    ? $store.state.user.username
                    : $store.state.user.email
                }}
              </a>
              <div class="vip_time">
                <!-- <a>到期时间: 2022年03月21日</a> -->
                <!-- <a v-show="Date.parse(new Date()).toString().substr(0, 10) < user.vip_expired_time">
          会员到期:{{ YS.toTime2(user.vip_expired_time) }}
        </a>
        <a v-show="Date.parse(new Date()).toString().substr(0, 10) > user.vip_expired_time">
          普通用户
        </a> -->
                <a>
                  {{
                    !user
                      ? ''
                      : Date.parse(new Date()).toString().substr(0, 10) >
                        $store.state.user.vip_expired_time
                      ? '您还不是会员'
                      : 'VIP剩余天数：' + YS.remainingTime($store.state.user.vip_expired_time)
                  }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- 支付选项 -->
        <div class="mvip_pay">
          <div class="mvip_pay_box-home" v-if="payList">
            <div
              class="mvip_pay_list"
              :class="{ 'mvip_pay_list-act': pay_index.top == index }"
              @click="payselect(item.id, index)"
              v-for="(item, index) in payList"
              :key="index"
            >
              <span class="mvip_1">{{ item.name }}</span>
              <div
                class="mvip_pay_box"
                :class="{
                  'mvip_pay_box-6': item.level == '6',
                  'mvip_pay_box-7': item.level == '7',
                }"
              >
                <span class="mvip_3">
                  {{ item.period }}天
                  <span class="mvip3_span" v-show="item.add_days">+{{ item.add_days }}天</span>
                </span>
                <span class="mvip_nonespan" v-show="item.promotion_id">
                  {{ item.price }}&nbsp;{{ item.currency }}
                </span>
                <div class="mvip_paynum">
                  <span class="mvip_2" v-show="item.promotion_id">
                    {{ item.discounted_price }}
                  </span>
                  <span class="mvip_2" v-show="!item.promotion_id">
                    {{ item.price }}
                  </span>
                  <a>{{ item.currency }}</a>
                </div>
                <span class="mvip_bottom_xiahua"></span>

                <img
                  class="mvip_back_vip"
                  src="../../assets/img/mvip_black.png"
                  alt="vip卡片背景图"
                />
                <img v-if="item.level == '6'" class="mvip_rt_1" src="../../assets/img/2ren.png" />
                <img
                  v-else-if="item.level == '7'"
                  class="mvip_rt_2"
                  src="../../assets/img/5ren.png"
                />
                <img
                  v-else-if="item.period == 365 && item.level != '6' && item.level != '7'"
                  class="mvip_rt_3"
                  src="../../assets/img/chaohuasuan.png"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mvip_pay_box-white" v-show="payList"></div>
        <!-- 选择支付方式 -->
        <div class="mvip_payway">
          <div class="mvip_title"><a>选择支付方式</a></div>
          <div class="mvip_body">
            <!-- <div class="mvip_box" v-for="(item, index) in payType" :key="index">
          <img class="mvip_way" :src="item.src" />
          <a v-show="index == 3">卡密支付</a>
          <img class="mvip_goway" src="../../assets/img/paygo.png" />
        </div> -->

            <!-- 套餐遍历 -->
            <div
              class="mvip_box"
              :class="{ pb_act_mobile: pay_index.bottom == index }"
              v-for="(item, index) in payType"
              :key="index"
              @click="hoverpaytype(item, index)"
            >
              <img class="paytype_img" :src="item.icon || payicon[item.symbol]" :alt="item.name" />
              <img
                v-show="pay_index.bottom == index"
                class="paytype_img1"
                src="../../assets/img/payact.png"
                alt="选择支付的图标"
              />
            </div>
          </div>
        </div>
        <!-- /会员特权介绍 -->
        <div class="mvip_can">
          <div class="mvipcan_t">
            <img class="mvipcanimg" src="../../assets/img/mvipcan.png" alt="会员特权介绍图标" />
            <span class="mvipte">会员特权介绍</span>
          </div>
          <div class="mvip_card">
            <div class="mvip_clist">
              <img src="../../assets/img/mv1.png" alt="清/蓝光清晰度图标" />
              <span>清/蓝光清晰度</span>
            </div>
            <div class="mvip_clist">
              <img src="../../assets/img/mv2.png" alt="倍数播放图标" />
              <span>倍数播放</span>
            </div>
            <div class="mvip_clist">
              <img src="../../assets/img/mv3.png" alt="VIP会员独享片源图标" />
              <span>VIP会员独享片源</span>
            </div>
            <div class="mvip_clist">
              <img src="../../assets/img/mv4.png" alt="跳过片头/片尾图标" />
              <span>跳过片头/片尾</span>
            </div>
            <div class="mvip_clist">
              <img src="../../assets/img/mv5.png" alt="过滤广告图标" />
              <span>过滤广告</span>
            </div>
            <div class="mvip_clist">
              <img src="../../assets/img/mv6.png" alt="VIP会员标识图标" />
              <span>VIP会员标识</span>
            </div>
          </div>
        </div>
        <div class="respaym respaym_2">
          <div
            v-if="$store.state.islogin"
            class="respaym_2-div respaym_2-left"
            @click="
              YS.routetogo({
                path: '/mgetta',
              })
            "
          >
            <span class="respaymwhite">
              <span v-show="!payCli">为Ta开通VIP</span>
              <div class="dots-6" v-show="payCli"></div>
            </span>
          </div>
          <div v-if="$store.state.islogin" class="respaym_2-div respaym_2-right">
            <span
              class="respaymspan"
              :class="{ respaynone: pay_index.bottom == null, respaycli: payCli }"
            >
              <!-- 遮罩层，用于让不可点击时，点击A标签不跳转 -->
              <div class="onfirmPay-mask" v-show="pay_index.bottom == null || payUrl == ''"></div>
              <!-- @click="gopay()" -->
              <!-- target="_blank" -->
              <a
                class="onfirmPay"
                v-show="!payCli"
                :href="!payCli ? payUrl : 'javascript:void(0);'"
              >
                确认支付
              </a>
              <div class="dots-6" v-show="payCli"></div>
            </span>
          </div>
          <div
            v-if="!$store.state.islogin"
            class="respaym_3-div respaym_2-left"
            @click="unloginCli"
          >
            <span class="respaymspan respaym_3">
              <span v-show="!payCli">立即登录</span>
              <div class="dots-6" v-show="payCli"></div>
            </span>
          </div>
        </div>
        <!-- <div class="respaym_block"></div> -->
      </template>
      <Mbottom></Mbottom>
      <!-- WIN 查看支付结果 -->
      <transition name="mcenter_2">
        <div class="mpayresult2" v-show="mpayresult">
          <!-- <div class="mpayresult2" v-show="true"> -->
          <div class="payrbody3">
            <a>检测到您24小时内已成功付款了一笔订单</a>
            <a>
              订单时间({{ new Date().toString().split('(')[1].split(')')[0] }})：{{
                YS.toTime(pay_ok)
              }}
            </a>
            <img src="../../assets/img/m_none2.0@2x.png" alt="警告图片" />
          </div>
          <div class="payrbody16">
            <div class="payrbodyflex2">
              <a class="payb_1 payb_3" @click="clopayres('mpayresult')">仍要充值</a>
              <a class="payb_2" @click="seepayres">查看支付结果</a>
            </div>
          </div>

          <img
            class="login-close"
            @click="clopayres('mpayresult')"
            src="../../assets/img/close.png"
            alt="关闭查看支付结果弹窗"
          />
        </div>
      </transition>
      <!-- WIN 充值成功 -->
      <transition name="mcenter_2">
        <div class="mpayresult2" v-show="paysucc">
          <!-- <div class="mpayresult2" v-show="true"> -->
          <div class="payrbody3">
            <a>充值成功</a>
            <img src="../../assets/img/mvip_ok.png" alt="充值成功提示" />
          </div>

          <div class="payafter">
            <span @click="clopayres('paysucc', false)">确认</span>
          </div>

          <img
            class="login-close"
            @click="clopayres('paysucc', false)"
            src="../../assets/img/close.png"
            alt="关闭充值成功弹窗"
          />
        </div>
      </transition>
      <!-- WIN 充值失败 -->
      <transition name="mcenter_2">
        <div class="mpayresult2" v-show="payfail">
          <!-- <div class="mpayresult2" v-show="true"> -->
          <div class="payrbody3">
            <a class="mvip_fail">出现了问题</a>
            <a>{{ failmess }}</a>
            <img class="marb" src="../../assets/img/mvip_fail.png" alt="充值失败" />
          </div>
          <div class="payafter">
            <span
              @click="
                clopayres('payfail');
                openandclo('kami', true);
                vipcardact = '';
              "
            >
              返回重试
            </span>
          </div>

          <img
            class="login-close"
            @click="clopayres('payfail')"
            src="../../assets/img/close.png"
            alt="关闭充值失败弹窗"
          />
        </div>
      </transition>
      <!-- WIN 需要登录 -->
      <transition name="mcenter_2">
        <div class="mpayresult2" v-show="needlogin">
          <!-- <div class="mpayresult2" v-show="true"> -->
          <div class="payrbody3">
            <a class="mvip_fail">需要登录才能进行操作，请前往登录</a>
            <img class="marb" src="../../assets/img/mvip_fail.png" alt="充值失败" />
          </div>
          <div class="payafter">
            <span @click="gologin">前往登录</span>
          </div>

          <img
            class="login-close"
            @click="gologin"
            src="../../assets/img/close.png"
            alt="关闭充值失败弹窗"
          />
        </div>
      </transition>
      <!-- WIN 卡密充值 -->
      <transition name="mcenter_2">
        <div class="mpayresult1" v-show="kami">
          <div class="payrbody8">
            <a>请输入卡密：</a>
          </div>
          <div class="payrbody9">
            <input
              v-model="vipcardact"
              @keyup.enter="vipcardcli"
              maxlength="255"
              placeholder="请输入"
            />
            <a @click="vipcardcli">确认充值</a>
          </div>

          <img
            class="login-close"
            @click="clokami()"
            src="../../assets/img/close.png"
            alt="关闭卡密充值弹窗"
          />
        </div>
      </transition>

      <!-- WIN 联系客服 -->
      <transition name="mcenter_2">
        <div class="mpayresult" v-show="custok">
          <div class="callcustomer">
            <img
              v-if="customer_service_list"
              :src="customer_service_list.wechat_qrcode"
              alt="联系客服的微信二维码"
            />
            <span class="cust01">截图保存打开微信扫码添加</span>
            <span class="cust02">客服在线时间：{{ timeText }}</span>
            <span class="cust02">{{ timeArea }}</span>
          </div>
          <img
            class="login-close"
            @click="openandclo('custok', false)"
            src="../../assets/img/close.png"
            alt="关闭联系客服弹窗"
          />
        </div>
      </transition>
      <!-- 遮罩层 -->
      <div id="coverm"></div>
    </div>
  </div>
</template>
<script>
let that;
let fetch;
let vuex;
import Mtop from '../../components/moblie/mTop';
import Mbottom from '../../components/moblie/mBottom';
export default {
  components: {
    Mtop,
    Mbottom,
  },
  name: 'mVip',
  data() {
    return {
      payUrl: '',
      timeText: '', //客服在线时间
      timeArea: '', //客服时间时区
      pay_ok: '',
      needlogin: false, //需要登录
      payCli: false, //点击支付
      failmess: '', //失败提示
      payfail: false, //充值失败弹窗
      paysucc: false, //充值成功弹窗
      custok: false, //客服充值弹窗
      customer_service_list: [], //二维码列表
      vipcardact: '',
      mpayresult: false,
      kami: false,
      pay_index: {
        top: 0,
        bottom: null,
      },
      //支付接口的参数
      gotoPay: {
        vip_card_category_id: '',
        payment_method_id: '',
        platform: 2,
      },
      user: {},
      tokenval: {},
      payList: [],
      payType: [],
      payicon: {
        paypal: require('../../assets/img/pay1.png'),
        alipay: require('../../assets/img/pay2.png'),
        wechat: require('../../assets/img/pay3.png'),
        'vip-card': require('../../assets/img/pay4.png'),
        artificial: require('../../assets/img/pay5.png'),
      },
    };
  },
  created() {
    that = this;
    fetch = this.api.user;
    vuex = this.$store.state;
    that;
    fetch;
    vuex;
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    if (!that.YS.ismob()) {
      //web端地址
      window.location.href =
        window.g.ub_index == 2
          ? window.g.WEB_URL_BO
          : window.g.ub_index == 3
          ? window.g.WEB_URL_U
          : window.g.WEB_URL + '/';
    }
  },
  mounted() {
    that.$store.commit('changelogin');
    // if (!that.$store.state.islogin) {
    //   // alert('需要登录才能进行操作，即将前往登录');
    //   that.openandclo('needlogin', true);
    //   //添加vuex队列方法，用于登录后
    //   that.$store.commit('addloginBefore', () => {
    //     that.YS.routetogo({ path: '/mrecord' });
    //   });
    //   // that.YS.routetogo({ path: '/mlogin' });
    //   return;
    // }
    if (localStorage.getItem('pay_ok')) {
      if ((localStorage.getItem('pay_ok') + 24 * 60 * 60) * 1000 > new Date().getTime()) {
        that.openandclo('mpayresult', true);
        that.pay_ok = Math.round(localStorage.getItem('pay_ok'));
      }
    }
    // this.YS.actgo('mvip_pay_list', 'mvip_pay_list-act', 0);
    that.user = JSON.parse(localStorage.getItem('user'));
    that.tokenval = JSON.parse(localStorage.getItem('token'));
    that.vipCardcategory(); //获取点卡种类
    that.paymentmethod(); //获取支付方式
    try {
      let timeCha = -(new Date().getTimezoneOffset() / 60);
      // debugger;
      let stratTime = that.$store.state.onlineTime.startTime + timeCha;
      let endTime = that.$store.state.onlineTime.endTime + timeCha;
      let countNum = 0;
      while (stratTime < 0 && countNum < 10) {
        stratTime += 24;
        countNum += 1;
      }
      while (stratTime >= 24 && countNum < 10) {
        stratTime -= 24;
        countNum += 1;
      }
      while (endTime < 0 && countNum < 10) {
        endTime += 24;
        countNum += 1;
      }
      while (endTime > 24 && countNum < 10) {
        endTime -= 24;
        countNum += 1;
      }
      let string_stratTime = stratTime.toString();
      let string_endTime = endTime.toString();
      if (string_stratTime.length < 2) string_stratTime = '0' + string_stratTime;
      if (string_endTime.length < 2) string_endTime = '0' + string_endTime;
      that.timeText = string_stratTime + ':00-' + string_endTime + ':00';
    } catch (error) {
      console.log(error.message);
    }
    try {
      let linshiArea = new Date().toString().split('(')[1].split(')')[0];
      that.timeArea = linshiArea;
    } catch (error) {
      console.log(error.message);
    }
  },
  methods: {
    //未登录点击登录
    unloginCli() {
      if (that.$store.state.islogin) {
        return;
      }
      that.$store.commit('addloginBefore', () => {
        that.YS.routetogo({ path: '/mrecord' });
      });
      that.gologin();
    },
    //开关窗口
    openandclo(type, boo) {
      that[type] = boo;
      var coverm = document.querySelector('#coverm');
      if (boo) {
        coverm.style.display = 'block';
      } else {
        coverm.style.display = 'none';
      }
    },
    gologin() {
      that.YS.routetogo({ path: '/mlogin' });
    },
    //获取可用的支付方式
    paymentmethod() {
      fetch.api_paymentmethod({}).then((data) => {
        that.payType = data.payment_method_list;
      });
    },
    //获取点卡种类
    vipCardcategory() {
      fetch.api_vipCardcategory({}).then((data) => {
        // data;
        that.payList = data.vip_card_category_list;
        //默认选中第一个
        that.gotoPay.vip_card_category_id = data.vip_card_category_list[0].id;
      });
    },
    //选择点卡
    payselect(id, i) {
      that.gotoPay.vip_card_category_id = id;
      that.pay_index.top = i; //当前选择的css下标
      // that.YS.actlist(that.paymeallist, i, 'pb_act');
    },
    //前往支付
    gopay() {
      that.payUrl = '';
      if (that.mindex == null || that.payCli) return;
      that.payCli = true;
      // 卡密支付
      if (that.mitem.symbol == 'vip-card') {
        // that.YS.gotoVip(1, 'open', 'vipcardWIN');
        that.openkami();
        // that.$store.commit('changeEvery', { key: 'showlogin', value: true, key2: 'vipcardWIN' });
      } else if (that.mitem.symbol == 'artificial') {
        //人工
        that.customerService();
        that.payCli = false;
      } else {
        that.gotoPay.payment_method_id = that.mitem.id;
        that.pay_index.bottom = that.mindex;
        that.paymentpurchaseVipCard();
      }
    },
    //接口——购买会员卡/前去支付
    paymentpurchaseVipCard() {
      fetch
        .api_paymentpurchaseVipCard(that.gotoPay)
        .then((data) => {
          if (!data) return;
          if (!data.redirect_url) return;
          that.rightnowPay = data;
          // that.YS.gotoVip(0, 'open', 'payresult'); //确认支付结果弹窗
          // var coverm = document.querySelector('#coverm');
          // coverm.style.display = 'block';

          // setTimeout(() => {
          //   that.openandclo('mpayresult', true);
          // }, 3000);
          // window.location.href = data.redirect_url;
          // window.open(data.redirect_url);
          that.payUrl = data.redirect_url;
        })
        .catch((error) => {
          // alert(error.message);
          that.failmess = error.message;
          that.openandclo('payfail', true);
          that.pay_index.bottom = null;
        })
        .finally(() => {
          that.payCli = false;
        });
    },
    //关闭支付弹窗
    clopayres(win) {
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'none';
      that[win] = false;
    },
    //查看支付结果
    seepayres() {
      that.YS.routetogo({ path: '/mcenter', query: { go: 'paySecond' } });
    },
    //卡密充值打开
    openkami() {
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'block';
      that.kami = true;
    },
    //卡密充值关闭
    clokami() {
      var coverm = document.querySelector('#coverm');
      coverm.style.display = 'none';
      that.kami = false;
      that.payCli = false;
    },
    //vip点卡激活事件
    vipcardcli() {
      fetch
        .api_vipCardactivate({ cipher: that.vipcardact })
        .then((data) => {
          var tem_local = JSON.parse(localStorage.getItem('user'));
          tem_local.vip_expired_time = data.user.vip_expired_time;
          localStorage.setItem('user', JSON.stringify(tem_local));
          that.$store.commit('changelogin');
          that.openandclo('kami', false);
          that.openandclo('paysucc', true);
          that.YS.routetogo({ path: '/mcenter' });
        })
        .catch((error) => {
          that.failmess = error.message;
          that.openandclo('kami', false);
          that.openandclo('payfail', true);
        });
    },
    //获取客服信息
    customerService() {
      fetch.api_customerServiceinfo({ symbol: 'purchase_vip_card' }).then((data) => {
        that.customer_service_list = data.customer_service_list;
        that.openandclo('custok', true);
      });
    },
    //选择样式
    hoverpaytype(item, index) {
      that.pay_index.bottom = index;
      that.mitem = item;
      that.mindex = index;
      that.gopay();
    },
  },
};
</script>
<style lang="scss" scope>
.m_bottom {
  display: none;
}
.mvip_pay::-webkit-scrollbar {
  display: none;
}
</style>
